import React, { useState, useCallback, useEffect } from "react";
import { models } from "powerbi-client";
import "powerbi-report-authoring";
import "./PowerBI.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { getPowerBIEmbedToken } from "../../store/services/powerBI.service";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useSelector } from "react-redux";
import { useGetPowerBiAuthToken } from "../../hooks/useAppToken";
import { Button } from "../Button/Button";
import { ToggleSwitch } from "../CommonUIComponents/ToggleSwitch/ToggleSwitch";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import {
  getPowerBIToggle,
  updatePowerBIToggle,
} from "../../store/services/engagement.service";

export function PowerBI() {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const { state } = useLocation();
  const user = useSelector((state) => state.user.user);
  const accessToken = useGetPowerBiAuthToken();
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const history = useNavigate();
  const [toggle, setToggle] = useState(true);
  const userConsent = useSelector(
    (state) => state.engagement?.externalUserFlag
  );

  useEffect(() => {
    setInitialToggle();
  }, []);

  const setInitialToggle = async () => {
    let request = { engagementId: state.engagementId };
    const data = await getPowerBIToggle({ request });
    if (state?.module === "M1") {
      data.M1_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M2") {
      data.M2_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M3") {
      data.M3_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    } else if (state?.module === "M4") {
      data.M4_toggle_enable === "Y" ? setToggle(true) : setToggle(false);
    }
  };
  const { reportId, module, engagementId, engagementCode } = state;

  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    // id: state?.reportId,
    // tokenType: models.TokenType.Aad,
    id: undefined,
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    accessToken: undefined,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  const getReport = useCallback(
    async (token) => {
      const response = await getPowerBIEmbedToken({
        request: {
          username: user.username,
          datasetId: process.env.REACT_APP_POWER_BI_REPORT_ID_M1,
          accessToken: token,
          baseUrl: selectedRegion.apiUrl,
          module: module,
        },
      });

      const reportConfig = {
        // Id: state?.reportId,
        // EmbedUrl: `https://app.powerbi.com/reportEmbed?reportId=${state?.reportId}&autoAuth=true&filter=vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`,
        Id: response.data.reportId,
        // Id: '1f2d58d9-e147-4ecd-8503-260c4093220c',
        // EmbedUrl: "https://app.powerbi.com/reportEmbed?reportId=1F2D58D9-E147-4ECD-8503-260C4093220C&autoAuth=true&filter=vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}",
        //EmbedUrl: "https://app.powerbi.com/groups/d54805c7-4611-4c42-abf2-d6b12cb9015c/reports/1f2d58d9-e147-4ecd-8503-260c4093220c",
        EmbedUrl: response.data.embedUrl,
        Type: "PowerBIReport",
        EmbedToken: {
          Token: response.data.embedToken,
          TokenId: response.data.embedTokenId,
          Expiration: response.data.expiration,
        },
        MinutesToExpiration: 1,
        AccessToken: response.data.embedToken,
        DefaultPage: null,
        MobileDefaultPage: null,
      };

      setReportConfig({
        ...reportConfig,
        embedUrl: reportConfig.EmbedUrl,
        // accessToken: reportConfig.EmbedToken.Token,
        accessToken: reportConfig.AccessToken,
      });
    },
    [state?.reportId]
  );

  useEffect(() => {
    if (!accessToken) return;

    getReport(accessToken);
  }, [getReport, accessToken]);

  // useEffect(() => {
  //   getReport();
  // }, [getReport]);

  const goBack = () => {
    history(-1);
  };

  function getEmbedUrl() {
    // let projectQuery = project?.projectId
    //   ? `vw_decarb_project/ProjectID eq '${project?.projectId}'`
    //   : `vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`;

    // if (state?.scenarioId) {
    //   projectQuery = `vw_Scenario/ScenarioId eq '${state?.scenarioId}'`;
    // }

    let projectQuery = `vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`;

    return `https://app.powerbi.com/reportEmbed?reportId=${state?.reportId}&autoAuth=true&filter=${projectQuery}`;
  }
  const replaceToggle = async (event) => {
    setToggle(!toggle);
    let request = {
      module: state?.module,
      engagementId: engagement.id,
      flagValue: event.target.checked ? "Y" : "N",
    };
    const data = await updatePowerBIToggle({ request });
  };

  return (
    <>
      {/* <iframe
        title="Decarb-GHG Emissions Insights-M1"
        width="1140"
        height="500.25"
        src={getEmbedUrl()}
        frameBorder="0"
        allowFullScreen={true}
        className="report-container"
      ></iframe> */}
      <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={"report-container"}
      />
      <div className="powerbi-button">
        <Button
          variant="primary-alt"
          onClick={() => {
            goBack();
          }}
        >
          {"Close"}
        </Button>
        {!userConsent && (
          <Tooltip
            trigger={
              <ToggleSwitch
                offLabel={"No"}
                onLabel={"Yes"}
                id={"powerBi-toggle"}
                onToggle={replaceToggle}
                toggleChecked={toggle}
              ></ToggleSwitch>
            }
            variant="ghost"
            tooltipText={"Enable PowerBI for external users"}
          ></Tooltip>
        )}
      </div>
    </>
  );
}
